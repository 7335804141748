import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment-timezone';

import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  Alert,
  AlertTitle,
  Autocomplete,
  createFilterOptions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import BuildingSearch from '../inputs/BuildingSearch';
import { SMPHAnimals } from '../inputs/SpeciesSearch';
import HazardousSubstanceGroup from '../inputs/HazardousSubstanceGroup';
import EmailList from '../inputs/EmailList';
import FormDivider from '../design/FormDivider';
import StandardWrapper from '../design/StandardWrapper';

import { sleep, pigMatch, buildingNameToCode } from '../../utils';

import { GET_TOASTS } from '../../global/graphql';
import { ORDER_MUTATION, GET_ALL_PROTOCOL_OUTLINES, CREATE_ERROR } from './graphql';

const dateFormat = (date) => (date ? moment(date).format() : null);

const AnimalOrderForm = ({ user }) => {
  const blankOrderForm = {
    receivingContactName: user.firstName + ' ' + user.lastName,
    receivingContactEmail: user.emailAddress,
  };

  const vendors = [
    'BRMS Breeding Core',
    'Charles River',
    'Envigo',
    'Labcorp (formerly Covance)',
    'Jackson',
    'Liberty',
    'SRTC Arlington',
    'Taconic',
    'Twin Valley',
    'NIA',
    'Xenopus1',
  ];

  const [vars, setVars] = useState(blankOrderForm);
  const [loading, setLoading] = useState(false);
  const [vendorList, setVendorList] = useState(vendors);
  const [otherVendor, setOtherVendor] = useState(false);
  const [arrowProtocol, setArrowProtocol] = useState(true);
  const [namedContacts, setNamedContacts] = useState([
    {
      name: user.firstName + ' ' + user.lastName,
      email: user.emailAddress,
      origin: 'submitter',
    },
  ]);

  const [breedingCore, setBreedingCore] = useState(false);

  const [infoToasts, setInfoToasts] = useState([]);
  const [successToast, setSuccessToast] = useState();
  const [protocolWarning, setProtocolWarning] = useState(false);
  const [pigWarningAcknowledged, setPigWarningAcknowledged] = useState(false);
  const [pigWarning, setPigWarning] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [dateError, setDateError] = useState(false);

  // Info toast logic
  const { data: toastData, loading: toastsLoading } = useQuery(GET_TOASTS, {
    variables: { form: 'animalOrder' },
  });

  useEffect(() => {
    if (!toastsLoading && Array.isArray(toastData?.toasts)) {
      setInfoToasts(toastData.toasts.map((x) => ({ ...x, active: true })));
    }
  }, [toastsLoading, toastData?.toasts]);

  const disableInfoToast = (_id) => {
    setInfoToasts(
      infoToasts.map((x) => {
        if (_id === x._id) {
          return { ...x, active: false };
        }
        return { ...x };
      })
    );
  };

  const [createError] = useMutation(CREATE_ERROR);

  const [addOrder] = useMutation(ORDER_MUTATION, {
    onError(e) {
      setLoading(false);
      setErrorDialog(true);
      setSuccessToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'submitAnimalOrder',
          error: e ? JSON.stringify(e) : undefined,
          data: JSON.stringify({
            user,
            vars,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setSuccessToast(true);
        setVars(blankOrderForm);
        setBreedingCore(false);
      } else setSuccessToast(false);
    },
  });

  const handleInput = (key, value) => {
    setVars((prevState) => ({ ...prevState, [key]: value ?? '' }));
  };

  const { data: protocolData } = useQuery(GET_ALL_PROTOCOL_OUTLINES);
  const protocols = protocolData?.allProtocolOutlines;

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessToast(false);
    setErrorDialog(false);
    setProtocolWarning(false);
    setDateError();
    setPigWarning(false);
  };

  function isNumeric(str) {
    if (typeof str !== 'number' && typeof str !== 'string') return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  const getNumAnimals = () => {
    let total = 0;
    if (isNumeric(vars.numFemales)) total += vars.numFemales;
    if (isNumeric(vars.numMales)) total += vars.numMales;
    if (isNumeric(vars.numNoSex)) total += vars.numNoSex;
    return total;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const variables = { ...vars };
    if (variables.dateRequired && !moment(variables.dateRequired).isValid()) {
      setDateError('Date must be MM/DD/YYYY');
      return;
    }
    setLoading(true);
    sleep(500);
    variables.dateRequired = dateFormat(variables.dateRequired);
    variables.receivingProtocolNumber = variables.receivingProtocolNumber.protocolNumber;
    if (!variables.immunodeficient) variables.immunodeficient = false;
    if (!variables.hazardExposure) variables.hazardExposure = false;
    if (!variables.perDiemBillingString) variables.perDiemBillingString = variables.orderBillingString;
    if (variables.vendor === 'none') delete variables.vendor;
    if (variables.vendor === 'other') variables.vendor = variables.otherVendor;
    if (variables.vendor === 'BRMS Breeding Core') {
      variables.species = 'Domestic Mouse';
      delete variables.emergencyContactPhone;
    }
    if (arrowProtocol && buildingNameToCode(variables.receivingBuilding)) {
      variables.receivingBuilding = buildingNameToCode(variables.receivingBuilding);
    }
    if (!variables.numMales) {
      variables.numMales = 0;
    }
    if (!variables.numFemales) {
      variables.numFemales = 0;
    }
    if (!variables.numNoSex) {
      variables.numNoSex = 0;
    }
    if (!variables.numCages) {
      delete variables.numCages;
    }

    addOrder({ variables });
  };

  const handleBreedingCore = (forBreedingCore) => {
    if (forBreedingCore) {
      setBreedingCore(true);

      setVars((vars) => ({
        ...vars,
        receivingProtocolNumber: protocols?.find((options) => options.protocolNumber === 'M005751'),
        receivingBuilding: 'SMI Bardeen',
        room: '640',
        receivingPI: 'Peter, Jody',
        receivingContactPhone: '608-234-2511',
        receivingContactEmergencyPhone: '608-219-1071',
        receivingContactEmail: 'brms-rs-mouse@g-groups.wisc.edu',
        species: 'Mouse',
        vendor: '',
      }));
      setArrowProtocol(true);

      setVendorList((prev) =>
        prev.filter((v) => v !== 'BRMS Breeding Core' && v !== 'Xenopus1' && v !== 'SRTC Arlington')
      );
    } else {
      setBreedingCore(false);
      setVars(blankOrderForm);
      setVendorList(vendors);
    }
  };

  return (
    <StandardWrapper>
      <Typography variant="h1">BRMS Animal Order</Typography>

      <Typography variant="h3">
        Animal orders must be submitted by 4:00 PM on Wednesday for delivery the following week.
      </Typography>
      <form
        id="animal-order-form"
        onSubmit={handleSubmit}
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Grid container alignContent="center" justifyContent="center" spacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            <FormDivider text="Order Information" />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ width: '100%' }}
              control={
                <Checkbox
                  checked={breedingCore}
                  onChange={(e) => {
                    handleBreedingCore(e.target.checked);
                  }}
                />
              }
              label="Check here if the animals are going into the Mouse Breeding Core"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {arrowProtocol ? (
              <Autocomplete
                filterOptions={createFilterOptions({ limit: 5 })}
                options={protocols ? protocols : ['loading']}
                getOptionLabel={(option) => option.label}
                value={vars.receivingProtocolNumber || null}
                onChange={(e, value) => {
                  if (value) {
                    if (value.protocolNumber.charAt(0) !== 'M') setProtocolWarning(true);
                    handleInput('receivingProtocolNumber', value);
                    handleInput('receivingPI', value.label.split(':')[1]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    placeholder="Enter protocol"
                    label="Protocol"
                    disabled={breedingCore}
                  />
                )}
                isOptionEqualToValue={(option, value) => option === value || value === ''}
              />
            ) : (
              <TextField
                fullWidth
                required
                label="Protocol Number"
                placeholder="Enter Protocol Number"
                value={vars.receivingProtocolNumber || ''}
                onChange={(e) => handleInput('receivingProtocolNumber', e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required={!arrowProtocol}
              label={arrowProtocol ? 'PI' : 'Enter PI Name'}
              placeholder="PI Name"
              value={vars.receivingPI || ''}
              onChange={(e) => handleInput('receivingPI', e.target.value)}
              disabled={arrowProtocol}
            />
          </Grid>
          {breedingCore && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  label="Ordering PI"
                  placeholder="Ordering PI Name"
                  value={vars.orderingPI || ''}
                  onChange={(e) => handleInput('orderingPI', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Spacer */}
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              style={{ width: '100%' }}
              control={
                <Checkbox
                  checked={arrowProtocol}
                  onChange={(e) => {
                    setArrowProtocol(e.target.checked);
                    setBreedingCore(false);
                    setVars(blankOrderForm);
                  }}
                  disabled={breedingCore}
                />
              }
              label="ARROW Protocol"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              disablePast
              value={vars.dateRequired || null}
              inputVariant="outlined"
              label="Requested Delivery Date"
              format="MM/dd/yyyy"
              onChange={(e) => {
                if (!e) return;
                let date = new Date(Date.parse(e));

                const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                const isTyped = date > Date.now();

                if (isWeekend && isTyped) setDateError('Animal orders cannot be requested to arrive on weekends.');
                else handleInput('dateRequired', dateFormat(e));
              }}
              renderInput={(props) => <TextField {...props} fullWidth helperText="Leave Blank for ASAP" />}
            />
          </Grid>
          {/* Contact Group */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="receivingContactName"
              value={vars.receivingContactName || ''}
              label="Contact Name"
              placeholder="Enter name"
              onChange={(e) => handleInput('receivingContactName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={true}
              fullWidth
              type="tel"
              value={vars.receivingContactPhone || ''}
              onChange={(e) => handleInput('receivingContactPhone', e.target.value)}
              label="Contact Phone"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="receivingContactEmail"
              label="Contact Email"
              value={vars.receivingContactEmail || ''}
              placeholder="Enter email"
              type="email"
              onChange={(e) => handleInput(e.target.name, e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {vars.vendor !== 'BRMS Breeding Core' && (
              <TextField
                required={true}
                fullWidth
                type="tel"
                value={vars.receivingContactEmergencyPhone || ''}
                onChange={(e) => handleInput('receivingContactEmergencyPhone', e.target.value)}
                label="Emergency Contact Phone"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Preferred Vendor</InputLabel>
              <Select
                style={{ textAlign: 'left' }}
                value={vars.vendor || ''}
                label="Preferred Vendor"
                onChange={(e) => {
                  handleInput('vendor', e.target.value);
                }}
              >
                {vendorList &&
                  vendorList.map((v, i) => (
                    <MenuItem value={v} key={i}>
                      {v}
                    </MenuItem>
                  ))}
                <MenuItem value="none">
                  <Typography>No preference</Typography>
                </MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {vars.vendor === 'other' && (
              <TextField
                fullWidth
                required={otherVendor === 'other'}
                label={'Other Vendor Name'}
                placeholder="Enter vendor name"
                value={vars.otherVendor || ''}
                onChange={(e) => handleInput('otherVendor', e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <BuildingSearch
              name="receivingBuilding"
              freeSolo={!arrowProtocol}
              required
              val={vars.receivingBuilding}
              sendInput={handleInput}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Room"
              placeholder="Enter room number"
              value={vars.room || ''}
              onChange={(e) => handleInput('room', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Order Billing String"
              placeholder="Enter billing string"
              value={vars.orderBillingString || ''}
              onChange={(e) => handleInput('orderBillingString', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Per Diem Billing String"
              placeholder="Enter billing string"
              value={vars.perDiemBillingString || ''}
              onChange={(e) => handleInput('perDiemBillingString', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDivider text="Animal Information" />
          </Grid>

          {vars.vendor === 'BRMS Breeding Core' ? (
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                type="number"
                onWheel={(e) => e.target.blur()}
                label="Number of Cages"
                value={vars.numCages || ''}
                onChange={(e) => handleInput('numCages', parseInt(e.target.value))}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 1000,
                  },
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={SMPHAnimals}
                value={vars.species || null}
                onChange={(e, value) => handleInput('species', value)}
                disabled={breedingCore}
                fullWidth
                renderInput={(params) => <TextField {...params} required placeholder="Enter species" label="Species" />}
                isOptionEqualToValue={(option, value) => option === value || value === ''}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              required={vars.vendor === 'BRMS Breeding Core'}
              fullWidth
              label="Age or Weight"
              placeholder="Enter age"
              value={vars.age || ''}
              onChange={(e) => handleInput('age', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={getNumAnimals() < 1}
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              label="Total Males"
              value={vars.numMales || ''}
              onChange={(e) => handleInput('numMales', parseInt(e.target.value))}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={getNumAnimals() < 1}
              fullWidth
              type="number"
              label="Total Females"
              onWheel={(e) => e.target.blur()}
              value={vars.numFemales || ''}
              onChange={(e) => handleInput('numFemales', parseInt(e.target.value))}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={getNumAnimals() < 1}
              fullWidth
              type="number"
              label="Total No Sex Preference"
              onWheel={(e) => e.target.blur()}
              value={vars.numNoSex || ''}
              onChange={(e) => handleInput('numNoSex', parseInt(e.target.value))}
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 1000,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required={vars.vendor === 'BRMS Breeding Core' || ['Mouse', 'Rat'].includes(vars.species)}
              fullWidth
              label={['Mouse', 'Rat'].includes(vars.species) ? 'Strain' : 'Breed or Strain'}
              placeholder="Enter strain"
              value={vars.strain || ''}
              onChange={(e) => handleInput('strain', e.target.value)}
            />
          </Grid>
          {vars.species && pigMatch(vars.species) && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Length of Stay"
                  value={vars.lengthOfStay || ''}
                  onChange={(e) => handleInput('lengthOfStay', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Spacer */}
              </Grid>
            </>
          )}
          <Grid item xs={12} textAlign="left">
            <FormControlLabel
              control={
                <Checkbox
                  checked={vars.immunodeficient || false}
                  onChange={(e) => handleInput('immunodeficient', e.target.checked)}
                />
              }
              label="Immunodeficient"
            />
          </Grid>
          {vars.vendor === 'BRMS Breeding Core' && (
            <Grid item xs={12} textAlign="left">
              <HazardousSubstanceGroup val={vars} sendInput={handleInput} />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Description of animals"
              placeholder="Enter additional details about animals"
              multiline
              fullWidth
              minRows={3}
              maxRows={6}
              value={vars.animalDescription || ''}
              onChange={(e) => handleInput('animalDescription', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required={!!vars.species && pigMatch(vars.species)}
              label="Special Requirements / Other Information"
              placeholder="Enter additional comments/instructions"
              multiline
              fullWidth
              minRows={3}
              maxRows={6}
              value={vars.addlRequirements || ''}
              onChange={(e) => handleInput('addlRequirements', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormDivider text="Other Contacts" />
          </Grid>
          <Grid item xs={12}>
            <EmailList
              sendInput={handleInput}
              existingContacts={namedContacts}
              instructions={
                'A copy of this document will be sent to the contacts provided above. You may add email addresses for additional contacts from sending or receiving groups.'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Our facilities do not typically exclude organisms considered to be part of the normal flora that may be
              present in animals received from approved vendors. If you are concerned about certain organisms and if
              they are present in the rodent strains you are requesting, please check the vendor's website for a
              complete listing of health status.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{ width: 120, float: 'right' }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={successToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Animal Order has been submitted!
        </Alert>
      </Snackbar>
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The order failed to submit.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={protocolWarning} onClose={handleToastClose}>
        <DialogTitle>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This tool is intended specifically for BRMS animal orders or orders being placed in the BRMS breeding core.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={pigWarning} onClose={handleToastClose}>
        <DialogTitle>{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            As of January 1st 2021, a fee of $150 per animal will be assessed at the time of ordering to account for
            increased disposal costs.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPigWarningAcknowledged(true);
              handleToastClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!dateError} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dateError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {infoToasts.map((toast) => (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={toast.active}
          onClose={() => disableInfoToast(toast._id)}
          key={toast._id}
        >
          <Alert severity="info">{toast.message}</Alert>
        </Snackbar>
      ))}
    </StandardWrapper>
  );
};

export default AnimalOrderForm;
